.layout-padding {
  max-width: 768px;
}
.responsive {
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.q-tab-pane > .q-carousel {
  height: 500px;
}
.q-gallery-carousel {
  width: 100%;
  max-height: 500px;
}
.type-name {
  margin: 15px;
}
.results-type {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
}
@media screen and (max-width: 600px) {
  .results-type {
    flex-direction: column;
  }
}
.results-type p {
  margin-top: 8px;
}
.btn-all {
  align-self: flex-end;
}
/*# sourceMappingURL=src/pages/ondemand/hotel/details/index.css.map */