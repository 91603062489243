



































































































.layout-padding
  max-width: 768px;
.item, .list-item
  height 90px
  width 100%
