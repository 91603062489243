.si-content-wrapper {
  padding: 8px;
  border: 0 !important;
  box-shadow: 1px 1px 30px 0px rgba(0,0,0,0.3);
}
.si-close-button,
.si-pointer-top,
.si-pointer-left {
  display: none;
}
.si-wrapper-top {
  cursor: pointer;
}
/*# sourceMappingURL=src/pages/ondemand/hotel/sidebar/map-modal/index.css.map */