.q-card {
  max-height: 120px;
  position: relative;
  background-color: #fff;
}
.segment {
  font-size: 2em;
  margin-top: 8px;
}
.segment .line {
  position: relative;
  margin: 0 8px;
  height: 1px;
  background-color: currentColor;
}
.segment .line:before,
.segment .line:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: currentColor;
  border-radius: 100%;
  top: -5px;
}
.segment .line:after {
  right: 0;
}
.price {
  font-size: 1.5em;
  margin: 8px 0;
}
.picture {
  background-size: 100%;
  background-position: center;
}
/*# sourceMappingURL=src/pages/ondemand/hotel/availability/hotel-card.css.map */