








































































































































































































































.layout-padding
  max-width 768px
span, .hr
  margin-top 16px
